import React from "react";
import "./index.css";

function App() {
    return (
	<div className="min-h-screen bg-gray-100 flex items-center justify-center">
	    <div className="p-6 bg-white shadow-lg rounded-lg">
		<h1 className="text-2xl font-semibold mb-6">React & Tailwind CSS</h1>
		<div>
		    <button className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded mr-2">
			Button 1
		    </button>
		    <button className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded mr-2">
			Button 2
		    </button>
		    <button className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded">
			Button 3
		    </button>
		</div>
	    </div>
	</div>
    );
}

export default App;
